import * as Yup from "yup";

const initialValuesUpdatePassword = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const UpdatePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Old password is required*"),
  newPassword: Yup.string()
    .required("New password is required*")
    .min(6, "Password must be at least 6 characters long."),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
});

export { initialValuesUpdatePassword, UpdatePasswordSchema };
