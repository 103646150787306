/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import CustomTable from "../../components/table/customTable";
import { RoutePath } from "../../routes/routePath";
import useGet from "../../hooks/useGet";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import userGlobalConfig from "../../utils/constants/globalConfig";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../components/customModal/customModal";
import { useFormik } from "formik";
import {
  addSkillInitialValues,
  addSkillValidationSchema,
} from "../../utils/validations/skill";
import usePost from "../../hooks/usePost";
import { toast } from "react-toastify";
import NotFound from "../../components/notFound";
import useDelete from "../../hooks/useDelete";
import usePut from "../../hooks/usePut";
import SkillListingSkeleton from "../../utils/skeleton/skillListingSkeleton";

const SkillsList = () => {
  const [deleteSkillModal, setDeleteSkillModal] = useState(false);
  const [deleteSkillId, setDeleteSkillId] = useState(null);

  const [uploadImg, setUploadImg] = useState(null);
  const [uploadBackgroundImg, setUploadBackgroundImg] = useState(null);
  const [skillModal, setSkillModal] = useState({
    title: "",
    data: {},
    open: false,
    type: "",
    actionTitle: "",
    onConfirm: null,
  });
  const nav = useNavigate();
  const uploadImageRef = useRef(null);
  const uploadBackgroundImageRef = useRef(null);
  const journeyId = JSON.parse(
    localStorage.getItem(userGlobalConfig?.JOURNEY_ID)
  );
  const { mutateAsync: addSkillMutateAsync, isLoading: isLoadingAddSkill } =
    usePost(ENDPOINTS.SKILL_ADD, QUERY_KEYS.SKILL_ADD_QUERY_KEY);
  const {
    data: skillListData,
    isLoading: isLoadingSkill,
    error: errorSkill,
    isSuccess: isSuccessSkill,
    refetch: refetchSkillList,
  } = useGet(ENDPOINTS.SKILLS_LIST, QUERY_KEYS.SKILLS_LIST_QUERY_KEY, {
    journey_id: journeyId,
  });

  const {
    isLoading: isLoadingSkillDelete,
    mutateAsync: SkillDeleteMutateAsync,
  } = useDelete(ENDPOINTS.SKILL_DELETE, QUERY_KEYS.SKILL_DELETE_QUERY_KEY);
  const skillData = skillListData?.data?.skill_list;
  const { mutateAsync: editSkillMutateAsync, isLoading: isLoadingEditSkill } =
    usePut(ENDPOINTS.SKILL_EDIT, QUERY_KEYS.SKILL_EDIT_QUERY_KEY);

  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: addSkillInitialValues,
    validationSchema: addSkillValidationSchema,
    onSubmit: async (values) => {
      if (skillModal.type === "Add") {
        const formData = new FormData();
        formData.append("journy_id", journeyId);
        formData.append("name", values?.skillName);
        formData.append("description", values?.skillDescription);
        formData.append("image", values?.skillImage);
        formData.append("background_image", values?.skillBackgroundImg);
        try {
          const response = await addSkillMutateAsync(formData);
          if (response.statusCode === 201) {
            toast.success(response?.message);
            handleCloseModal();
            refetchSkillList();
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else if (skillModal.type === "Edit") {
        const formData = new FormData();
        formData.append("_id", skillModal?.data?._id);
        formData.append("name", values?.skillName);
        formData.append("description", values?.skillDescription);
        formData.append("image", values?.skillImage);
        formData.append("background_image", values.skillBackgroundImg);
        try {
          const response = await editSkillMutateAsync(formData);
          if (response.statusCode === 200) {
            toast.success(response?.message);
            handleCloseModal();
            refetchSkillList();
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const onChangeFile = (target) => {
    const selectedFile = target.files[0];
    setUploadImg(URL.createObjectURL(selectedFile));
    setFieldValue("skillImage", selectedFile);
    target.value = "";
  };
  const onChangeBackgroundFile = (target) => {
    const selectedFile = target.files[0];
    setUploadBackgroundImg(URL.createObjectURL(selectedFile));
    setFieldValue("skillBackgroundImg", selectedFile);
    target.value = "";
  };

  const handleSelectUploadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };
  const handleSelectBackgroundUploadImage = () => {
    if (uploadBackgroundImageRef.current) {
      uploadBackgroundImageRef.current.click();
    }
  };

  const handelRemoveUploadImg = () => {
    setUploadImg(null);
    setFieldValue("skillImage", "");
  };
  const handelRemoveBackgroundUploadImg = () => {
    setUploadBackgroundImg(null);
    setFieldValue("skillBackgroundImg", "");
  };

  const handelSkillView = (item) => {
    nav(RoutePath.SKILL_DETAIL.path, {
      state: { headerName: RoutePath.SKILL_DETAIL.title },
    });
    localStorage.setItem(userGlobalConfig.SKILL_ID, JSON.stringify(item?._id));
  };

  const handelDeleteSkillModal = (id) => {
    setDeleteSkillId(id);
    setDeleteSkillModal(true);
  };
  const onDeleteConfirm = async () => {
    try {
      const response = await SkillDeleteMutateAsync({
        _id: deleteSkillId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteSkillModal(false);
        refetchSkillList();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleModalOpen = (e, item) => {
    if (e.target.title === "Add") {
      setSkillModal({
        open: true,
        title: "Add Skill",
        actionTitle: "Add",
        type: e.target.title,
        onConfirm: handleSubmit,
        data: {},
      });
    } else if (e.target.title === "Edit") {
      setSkillModal({
        open: true,
        title: "Edit Skill",
        actionTitle: "Save",
        type: e.target.title,
        onConfirm: handleSubmit,
        data: item,
      });
    } else return null;
  };

  const returnModal = () => {
    if (skillModal.type === "Add") {
      return (
        <CustomModal
          open={skillModal.open}
          title={skillModal.title}
          actionTitle={skillModal.actionTitle}
          cancelTitle={"Cancel"}
          actionButtonClass={"bg-danger"}
          onConfirm={skillModal.onConfirm}
          isLoading={isLoadingAddSkill}
          onClose={handleCloseModal}
        >
          <div>
            <form>
              <div className="upload_container">
                <div>
                  <div className="drag-input-field">
                    <label>Upload Image </label>
                    <input
                      type="file"
                      accept="image/*"
                      name="skillImage"
                      onBlur={handleBlur}
                      onChange={({ target }) => {
                        onChangeFile(target);
                      }}
                      ref={uploadImageRef}
                    />
                    {values?.skillImage?.length === 0 ? (
                      <label className="m-0" onClick={handleSelectUploadImage}>
                        <span className="upload-text">
                          <i className="fa-solid fa-cloud-arrow-up" />
                          Upload
                        </span>
                      </label>
                    ) : (
                      <div className="position-relative ">
                        <img
                          src={uploadImg}
                          style={{
                            height: "200px",
                            width: "200px",

                            objectFit: "contain",
                            borderRadius: "5px",
                            border: "solid 1px #8080808a",
                          }}
                          alt="..."
                        />
                        <button
                          className="removeimg"
                          type="button"
                          onClick={() => handelRemoveUploadImg()}
                        >
                          X
                        </button>
                      </div>
                    )}
                  </div>
                  {touched.skillImage && errors.skillImage && (
                    <p className="error" style={{ marginTop: "4px" }}>
                      {errors.skillImage}
                    </p>
                  )}
                </div>
                <div>
                  <div className="mb-3">
                    <label> Name</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter Name"
                      name="skillName"
                      value={values.skillName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.skillName && errors.skillName && (
                    <p className="error">{errors.skillName}</p>
                  )}
                  <div className="mb-3">
                    <label> Description </label>
                    <textarea
                      className="form-control form-control-lg"
                      placeholder="Enter  Description"
                      rows="3"
                      cols="50"
                      name="skillDescription"
                      value={values.skillDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                  {touched.skillDescription && errors.skillDescription && (
                    <p className="error">{errors.skillDescription}</p>
                  )}
                </div>
              </div>

              {/* <div className="drag-input-field mt-3">
                <label>Upload Background Image </label>
                <input
                  type="file"
                  accept="image/*"
                  name="skillBackgroundImg"
                  onBlur={handleBlur}
                  onChange={({ target }) => {
                    onChangeBackgroundFile(target);
                  }}
                  ref={uploadBackgroundImageRef}
                />
                {values?.skillBackgroundImg?.length === 0 ? (
                  <label
                    className="m-0"
                    onClick={handleSelectBackgroundUploadImage}
                  >
                    <span className="upload-text">
                      <i className="fa-solid fa-cloud-arrow-up" />
                      Upload image
                    </span>
                  </label>
                ) : (
                  <div className="position-relative ">
                    <img
                      src={uploadBackgroundImg}
                      style={{
                        height: "200px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                      alt="..."
                    />
                    <button
                      className="removeimg"
                      type="button"
                      onClick={() => handelRemoveBackgroundUploadImg()}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              {touched.skillImage && errors.skillImage && (
                <p className="error" style={{ marginTop: "4px" }}>
                  {errors.skillImage}
                </p>
              )} */}
            </form>
          </div>
        </CustomModal>
      );
    } else if (skillModal.type === "Edit") {
      return (
        <CustomModal
          open={skillModal.open}
          title={skillModal.title}
          actionTitle={skillModal.actionTitle}
          cancelTitle="Cancel"
          actionButtonClass="bg-danger"
          onConfirm={skillModal.onConfirm}
          isLoading={isLoadingEditSkill}
          onClose={handleCloseModal}
        >
          <div>
            <form>
              <div className="upload_container">
                <div>
                  <div className="drag-input-field">
                    <label>Upload Image </label>
                    <input
                      type="file"
                      accept="image/*"
                      name="skillImage"
                      onBlur={handleBlur}
                      onChange={({ target }) => {
                        onChangeFile(target);
                      }}
                      ref={uploadImageRef}
                    />
                    {values?.skillImage?.length === 0 ? (
                      <label className="m-0" onClick={handleSelectUploadImage}>
                        <span className="upload-text">
                          <i className="fa-solid fa-cloud-arrow-up" />
                          Upload
                        </span>
                      </label>
                    ) : (
                      <div className="position-relative ">
                        <img
                          src={
                            uploadImg === null ? values.skillImage : uploadImg
                          }
                          style={{
                            height: "200px",
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                          alt="..."
                        />
                        <button
                          className="removeimg"
                          type="button"
                          onClick={() => handelRemoveUploadImg()}
                        >
                          X
                        </button>
                      </div>
                    )}
                  </div>
                  {touched.skillImage && errors.skillImage && (
                    <p className="error" style={{ marginTop: "4px" }}>
                      {errors.skillImage}
                    </p>
                  )}
                </div>
                <div>
                  <div className="mb-3">
                    <label> Name</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter Name"
                      name="skillName"
                      value={values.skillName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.skillName && errors.skillName && (
                    <p className="error">{errors.skillName}</p>
                  )}
                  <div className="mb-3">
                    <label> Description </label>
                    <textarea
                      className="form-control form-control-lg"
                      placeholder="Enter  Description"
                      rows="3"
                      cols="50"
                      name="skillDescription"
                      value={values.skillDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div>
                  {touched.skillDescription && errors.skillDescription && (
                    <p className="error">{errors.skillDescription}</p>
                  )}
                </div>
              </div>

              {/* <div className="drag-input-field mt-3">
                <label>Upload Background Image </label>
                <input
                  type="file"
                  accept="image/*"
                  name="skillBackgroundImg"
                  onBlur={handleBlur}
                  onChange={({ target }) => {
                    onChangeBackgroundFile(target);
                  }}
                  ref={uploadBackgroundImageRef}
                />
                {values?.skillBackgroundImg?.length === 0 ? (
                  <label
                    className="m-0"
                    onClick={handleSelectBackgroundUploadImage}
                  >
                    <span className="upload-text">
                      <i className="fa-solid fa-cloud-arrow-up" />
                      Upload image
                    </span>
                  </label>
                ) : (
                  <div className="position-relative ">
                    <img
                      src={
                        uploadBackgroundImg === null
                          ? values?.skillBackgroundImg
                          : uploadBackgroundImg
                      }
                      style={{
                        height: "200px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                      alt="..."
                    />
                    <button
                      className="removeimg"
                      type="button"
                      onClick={() => handelRemoveBackgroundUploadImg()}
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              {touched.skillImage && errors.skillImage && (
                <p className="error" style={{ marginTop: "4px" }}>
                  {errors.skillImage}
                </p>
              )} */}
            </form>
          </div>
        </CustomModal>
      );
    } else return null;
  };

  const handleCloseModal = () => {
    setSkillModal({
      open: false,
      data: {},
    });
    resetForm();
  };

  useEffect(() => {
    if (skillModal.type === "Edit") {
      setValues({
        skillName: skillModal?.data?.name,
        skillDescription: skillModal?.data?.description,
        skillBackgroundImg: skillModal.data?.background_image,
        skillImage: skillModal?.data?.image,
      });
    }
  }, [skillModal?.data]);

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-12 mt-4 px-1">
            <div className="card">
              <div className="card-header pb-0 px-3">
                <div className="position-relative my-3 d-flex justify-center">
                  <h5 className="mb-0">Skill List</h5>
                  {isLoadingSkill ? null : (
                    <button
                      title="Add"
                      type="button"
                      className="btn btn-green text-uppercase position-absolute top-0 end-0 "
                      onClick={(e) => handleModalOpen(e)}
                    >
                      Add Skill
                    </button>
                  )}
                </div>
              </div>
              <div className="card-body pt-4 p-3">
                {isLoadingSkill ? (
                  <SkillListingSkeleton />
                ) : (
                  <ul className="list-group">
                    {!skillData ? (
                      <NotFound content="Skills List Not Found" />
                    ) : (
                      skillData?.map((item, index) => {
                        return (
                          <li
                            className="list-group-item border-0 p-4 mb-2 bg-gray-100 border-radius-lg"
                            key={index}
                          >
                            <div className="row justify-content-between gy-3">
                              <div
                                className="card-flex d-flex cursor-pointer col-sm-10"
                                onClick={() => handelSkillView(item)}
                              >
                                <img
                                  src={item.image}
                                  alt="Image"
                                  className="item-img rounded-2"
                                />

                                <div className="item-content d-flex flex-column ">
                                  <h6 className="mb-3 text-lg">{item.name}</h6>
                                  <span className="mb-2 text-md">
                                    <span className="text-dark font-weight-normal">
                                      {item.description}
                                    </span>
                                  </span>
                                </div>
                              </div>

                              <div className="col-sm-2 align-self-end ms-sm-auto text-end">
                                <a
                                  className="btn btn-link text-danger text-gradient px-3 mb-0"
                                  onClick={() =>
                                    handelDeleteSkillModal(item?._id)
                                  }
                                >
                                  <i className="far fa-trash-alt me-2" />
                                  Delete
                                </a>
                                <a
                                  title="Edit"
                                  className="btn btn-link text-dark px-3 mb-0"
                                  onClick={(e) => handleModalOpen(e, item)}
                                >
                                  <i
                                    className="fas fa-pencil-alt text-dark me-2"
                                    aria-hidden="true"
                                  />
                                  Edit
                                </a>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {returnModal()}
      <CustomModal
        customClass="sm-modal"
        open={deleteSkillModal}
        title={"Skill Delete"}
        actionTitle={"Delete"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={onDeleteConfirm}
        isLoading={isLoadingSkillDelete}
        onClose={() => setDeleteSkillModal(false)}
      >
        <div>
          <span>Are you sure you want to delete Skill?</span>
        </div>
      </CustomModal>
    </div>
  );
};
export default SkillsList;
