// auth-context.tsx
import React, { createContext, useState } from "react";
import userGlobalConfig from "../utils/constants/globalConfig";

export const AuthContext = createContext({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem(userGlobalConfig.TOKEN) === null || undefined
      ? false
      : true
  );

  const handleLogin = (token, role) => {
    try {
      localStorage.setItem(userGlobalConfig.TOKEN, token);
      localStorage.setItem(userGlobalConfig.ROLE, role);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error setting token:", error);
    }
  };

  const handleLogout = () => {
    try {
      localStorage.removeItem(userGlobalConfig.TOKEN);
      localStorage.removeItem(userGlobalConfig.ROLE);
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error removing token:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login: handleLogin, logout: handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
