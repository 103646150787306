/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import QUERY_KEYS from "../../services/queryKeys";
import ENDPOINTS from "../../services/endPoints";

import { toast } from "react-toastify";
import CustomModal from "../../components/customModal/customModal";
import useDelete from "../../hooks/useDelete";
import { RoutePath } from "../../routes/routePath";
import usePost from "../../hooks/usePost";
import useGet from "../../hooks/useGet";
import userGlobalConfig from "../../utils/constants/globalConfig";
import ImagesUrl from "../../utils/constants/images";
import { useFormik } from "formik";
import {
  sendNotificationInitialValues,
  sendNotificationValidationSchema,
} from "../../utils/validations/sendNotification";

const UserDetail = () => {
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);

  const [notificationModal, setNotificationModal] = useState(false);
  const nav = useNavigate();

  const { data: userDetailData, refetch: refetchUserDetail } = useGet(
    ENDPOINTS.USER_DETAIL,
    QUERY_KEYS.USER_DETAIL_QUERY_KEY,
    {
      user_id: JSON.parse(localStorage.getItem(userGlobalConfig.USER_ID)),
    }
  );
  const UserId = JSON.parse(localStorage.getItem(userGlobalConfig.USER_ID));
  const userData = userDetailData?.data;

  const { isLoading: isLoadingUserStatus, mutateAsync: userStatusMutateAsync } =
    usePost(ENDPOINTS.USER_STATUS, QUERY_KEYS.USER_STATUS_CHANGE_QUERY_KEY);
  const { isLoading: isLoadingUserDelete, mutateAsync: UserDeleteMutateAsync } =
    useDelete(ENDPOINTS.USER_DELETE, QUERY_KEYS.USER_DELETE_QUERY_KEY);
  const {
    isLoading: isLoadingNotificationStatus,
    mutateAsync: notificationStatusMutateAsync,
  } = usePost(
    ENDPOINTS.SEND_NOTIFICATION,
    QUERY_KEYS.SEND_NOTIFICATION_QUERY_KEY
  );
  const {
    errors,
    handleChange,
    handleBlur,
    values,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: sendNotificationInitialValues,
    validationSchema: sendNotificationValidationSchema,
    onSubmit: async (value) => {
      try {
        const response = await notificationStatusMutateAsync({
          title: value.title,
          message: value.message,
          user: UserId,
        });
        if (response.status === true) {
          setNotificationModal(false);
          toast.success(response?.message);
          resetForm();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    },
  });
  const onDeleteConfirm = async () => {
    try {
      const response = await UserDeleteMutateAsync({
        user_id: deleteUserId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteUserModal(false);
        nav(RoutePath.USER_MANAGEMENT.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handelDeleteUserModal = (id) => {
    setDeleteUserId(id);
    setDeleteUserModal(true);
  };
  const handleStatusChange = async (item) => {
    try {
      let statusCode = item.status === 1 ? "2" : "1";
      const response = await userStatusMutateAsync({
        _id: item._id,
        status: statusCode,
      });
      if (response.status === true) {
        toast.success(response?.message);
        refetchUserDetail();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="container-fluid py-4">
      <div className="row">
        <div className="card position-relative">
          <img
            className="back_btn"
            src={ImagesUrl.back_btn}
            alt=".."
            onClick={() =>
              nav(RoutePath.USER_MANAGEMENT.path, {
                state: { headerName: RoutePath.USER_MANAGEMENT.title },
              })
            }
          />
          <div className="card-body">
            <div className="row">
              <div className="d-flex justify-content-end ">
                <div className="align-middle">
                  <span className="text-secondary text-md font-weight-bold  ">
                    <div className="cursor-pointer">
                      <a
                        title="Send Notification"
                        className="btn btn-link text-dark mb-0 p-2 fs-5"
                        onClick={() => setNotificationModal(true)}
                      >
                        <i
                          class="fa-solid fa-paper-plane"
                          style={{ color: "#74C0FC" }}
                        ></i>
                      </a>
                      <a
                        title="Edit"
                        className="btn btn-link text-dark mb-0 p-2"
                        onClick={() =>
                          nav(RoutePath.EDIT_USER_DETAIL.path, {
                            state: {
                              headerName: RoutePath.EDIT_USER_DETAIL.title,
                              userDetail: userData,
                            },
                          })
                        }
                      >
                        <i className="fas fa-pencil-alt text-dark fs-5"></i>
                      </a>

                      <a
                        title="Delete"
                        className="btn btn-link text-dark mb-0 p-2 "
                        onClick={() => handelDeleteUserModal(userData?._id)}
                      >
                        <i className="far fa-trash-alt  text-danger fs-5 "></i>
                      </a>
                    </div>
                  </span>
                </div>
                <span className="toggle-box px-1">
                  <input
                    onClick={() => handleStatusChange(userData)}
                    type="checkbox"
                    checked={userData?.status === 1 ? true : false}
                    id={userData?._id}
                    disabled={isLoadingUserStatus}
                  />
                  <label className="m-0" htmlFor={userData?._id} />
                </span>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label">First name</label>
                  <input
                    className="form-control editInput"
                    readonly
                    disabled
                    value={userData?.first_name || "N/A"}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">Last name</label>
                  <input
                    className="form-control editInput"
                    readonly
                    disabled
                    value={userData?.last_name || "N/A"}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">Email address</label>
                  <input
                    className="form-control editInput"
                    readonly
                    disabled
                    value={userData?.email || "N/A"}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">Phone Number</label>
                  <input
                    className="form-control editInput"
                    readonly
                    disabled
                    value={
                      userData?.country_code && userData?.phone_number
                        ? `${userData?.country_code} ${userData?.phone_number}`
                        : "N/A"
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">Status</label>
                  <input
                    className="form-control editInput"
                    readonly
                    disabled
                    value={userData?.status === 1 ? "Active" : "Suspended"}
                  />
                </div>
              </div>
            </div>
            <CustomModal
              customClass="sm-modal"
              open={deleteUserModal}
              title={"User Delete"}
              actionTitle={"Delete"}
              cancelTitle={"Cancel"}
              actionButtonClass={"bg-danger"}
              onConfirm={onDeleteConfirm}
              isLoading={isLoadingUserDelete}
              onClose={() => setDeleteUserModal(false)}
            >
              <div>
                <span>Are you sure you want to delete user?</span>
              </div>
            </CustomModal>
            <CustomModal
              customClass="md-modal"
              open={notificationModal}
              title="Send Notification"
              actionTitle="Send"
              cancelTitle="Cancel"
              actionButtonClass="bg-danger"
              onConfirm={handleSubmit}
              isLoading={isLoadingNotificationStatus}
              onClose={() => {
                setNotificationModal(false);
                resetForm();
              }}
            >
              <div>
                <div class="mb-3 mt-2">
                  <div className="form-group">
                    <label
                      htmlFor="example-text-input"
                      className="form-control-label"
                    >
                      Title
                    </label>
                    <input
                      className="form-control "
                      type="text"
                      placeholder="title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.title && touched.title ? (
                    <p className="error">{errors.title}</p>
                  ) : null}
                  <label> Message </label>
                  <textarea
                    class="form-control form-control-lg"
                    placeholder="Wright here ..."
                    rows="7"
                    cols="50"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  {errors.message && touched.message ? (
                    <p className="error mt-2">{errors.message}</p>
                  ) : null}
                </div>
              </div>
            </CustomModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
