/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef } from "react";
import NotFound from "../../components/notFound";
import SkillListingSkeleton from "../../utils/skeleton/skillListingSkeleton";
import CustomModal from "../../components/customModal/customModal";
import TextEditor from "../../components/textEdtior";
import { useFormik } from "formik";
import {
  libraryInitialValues,
  libraryValidationSchema,
} from "../../utils/validations/library";
import useGet from "../../hooks/useGet";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import usePost from "../../hooks/usePost";
import { toast } from "react-toastify";
import useDelete from "../../hooks/useDelete";
import { Rating } from "react-simple-star-rating";
import ImagesUrl from "../../utils/constants/images";
import ReviewModal from "./reviewModal";

const LibraryListScreen = () => {
  const [uploadImg, setUploadImg] = useState(null);
  const [resourceId, setResourceId] = useState("");
  const [deleteLibraryModal, setDeleteLibraryModal] = useState(false);
  const [deleteLibraryId, setDeleteLibraryId] = useState(null);
  const uploadImageRef = useRef(null);
  const [reviewModal, setReviewModal] = useState(false);
  const [LibraryModal, setLibraryModal] = useState({
    title: "",
    data: {},
    open: false,
    type: "",
    actionTitle: "",
    onConfirm: null,
  });
  const handelUpLoadImage = async (callback) => {
    const formData = new FormData();
    formData.append("skill_id", values?.skillId);
    formData.append("resource_id", values?.resource_id);

    // Check if values.backgroundImg is a file or a URL string
    if (values.backgroundImg instanceof File) {
      // If it's a file, append it as you were doing before
      formData.append("image", values.backgroundImg);
      const response = await addImagesMutateAsync(formData);
      const imgUrl = response?.data?.resource_image;
      const imageBaseUrl = response?.data?.image_url;
      const resourceId = response?.data?.resource_id;

      callback(imgUrl, imageBaseUrl, resourceId);
    } else if (typeof values.backgroundImg === "string") {
      // If it's a URL (string), pass it directly to the callback
      const imgUrl = values.backgroundImg;
      callback(imgUrl);
    } else {
      // Handle the case where the value is neither a file nor a string
      console.error("Invalid background image");
    }
  };

  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    values,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: libraryInitialValues,
    validationSchema: libraryValidationSchema,
    onSubmit: async () => {
      handelUpLoadImage(handelUploadJson);
    },
  });
  const {
    data: libraryListData,
    isLoading: isLoadingLibrary,
    refetch: refetchLibrary,
  } = useGet(ENDPOINTS.LIBRARY_LISTING, QUERY_KEYS.LIBRARY_LISTING_QUERY_KEY);

  const LibraryListData = libraryListData?.data?.resources_list;
  const { data: resourceListData } = useGet(
    ENDPOINTS.RESOURCE_LIST,
    QUERY_KEYS.RESOURCE_LIST_QUERY_KEY
  );
  const options = resourceListData?.data?.resources_types || [];

  const { data: skillListData } = useGet(
    ENDPOINTS.SKILL_TYPE,
    QUERY_KEYS.SKILL_TYPE_QUERY_KEY
  );

  const { mutateAsync: addImagesMutateAsync } = usePost(
    ENDPOINTS.LIBRARY_IMAGE,
    QUERY_KEYS.LIBRARY_IMAGE_QUERY_KEY
  );
  const skillOption = skillListData?.data?.dropdown_skills;

  const {
    isLoading: isLoadingLibraryDelete,
    mutateAsync: LibraryDeleteMutateAsync,
  } = useDelete(ENDPOINTS.LIBRARY_DELETE, QUERY_KEYS.LIBRARY_DELETE_QUERY_KEY);

  const { mutateAsync: addLibraryMutateAsync, isLoading: isLoadingAddLibrary } =
    usePost(ENDPOINTS.ADD_LIBRARY, QUERY_KEYS.ADD_LIBRARY_QUERY_KEY);

  const handelUploadJson = async (ImageUrl, imageBaseUrl, resourceId) => {
    const imageURL =
      imageBaseUrl && imageBaseUrl.trim() !== ""
        ? `${imageBaseUrl}${ImageUrl}`
        : `${ImageUrl}`;
    const formdata = {
      resource_id: values.resource_id === "" ? resourceId : values.resource_id,
      skill_id: values.skillId,
      resource_json: {
        id: "",
        image: {
          form_unique_id: "",
          added_image: imageURL,
        },
        title: values.type,
        description: values?.title,
        author: values.author,
        time: values.time,
        cost_type: values.cost,
        overViewTitle: values.heading,
        overViewDes: values.description,
        overViewNote:
          "This resource is third party material. If you want to learn more, you will be redirected to another page outside of the LeadHerself app.",

        learnMoreLink: values?.url,
      },
    };

    try {
      const response = await addLibraryMutateAsync(formdata);

      if (response.statusCode === 200) {
        toast.success(response?.message);
        handleCloseModal();
        refetchLibrary();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleModalOpen = (e, item) => {
    if (e.target.title === "Add") {
      setLibraryModal({
        open: true,
        title: "Add Library Resource",
        actionTitle: "Add",
        type: e.target.title,
        onConfirm: () => handleSubmit(),
        data: {},
      });
    } else if (e.target.title === "Edit") {
      setLibraryModal({
        open: true,
        title: "Edit Library Resource",
        actionTitle: "Save",
        type: e.target.title,
        onConfirm: handleSubmit,
        data: item,
      });
    } else return null;
  };

  const handleCloseModal = () => {
    setLibraryModal({
      open: false,
      data: {},
    });
    resetForm();
  };

  const handelReviewModal = async (id) => {
    setReviewModal(true);
    setResourceId(id);
  };

  const onChangeFile = (target) => {
    const selectedFile = target.files[0];
    setUploadImg(URL.createObjectURL(selectedFile));
    setFieldValue("backgroundImg", selectedFile);
    target.value = "";
  };

  const handelRemoveUploadImg = () => {
    setUploadImg(null);
    setFieldValue("backgroundImg", "");
  };

  const handleSelectUploadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const handelChange = (e) => {
    setFieldValue("description", e);
  };

  const onDeleteConfirm = async () => {
    try {
      const response = await LibraryDeleteMutateAsync({
        resource_id: deleteLibraryId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteLibraryModal(false);
        refetchLibrary();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handelDeleteSkillModal = (id) => {
    setDeleteLibraryId(id);
    setDeleteLibraryModal(true);
  };

  const returnModal = () => {
    if (LibraryModal?.type === "Add") {
      return (
        <CustomModal
          open={LibraryModal.open}
          title={LibraryModal.title}
          actionTitle={LibraryModal.actionTitle}
          cancelTitle="Cancel"
          actionButtonClass="bg-danger"
          onConfirm={LibraryModal.onConfirm}
          isLoading={isLoadingAddLibrary}
          onClose={handleCloseModal}
          customClass="modal-xl"
        >
          <div>
            <form>
              <div className="upload_container">
                <div className="w-100 mt-2">
                  <div className="d-flex w-100 gap-4">
                    <div className="w-100">
                      <div className="mb-3">
                        <label for="cars">Select Skill </label>
                        <select
                          className="form-control"
                          style={{ padding: "12px" }}
                          name="skillId"
                          id="cars"
                          value={values.skillId}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            select skill
                          </option>
                          {skillOption?.map((value) => (
                            <option key={value._id} value={value._id}>
                              {value?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {touched.skillId && errors.skillId && (
                        <p className="error p-1">{errors.skillId}</p>
                      )}
                      <div className="mb-3">
                        <label for="cars">Select Type </label>
                        <select
                          className="form-control"
                          style={{ padding: "12px" }}
                          name="type"
                          id="cars"
                          value={values?.type}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            select type
                          </option>
                          {options?.map((value) => (
                            <option key={value.title} value={value.title}>
                              {value.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      {touched?.type && errors?.type && (
                        <p className="error p-1">{errors?.type}</p>
                      )}
                      <div className="mb-3">
                        <label for="cars">Select cost </label>

                        <select
                          className="form-control"
                          style={{ padding: "12px" }}
                          name="cost"
                          id="cars"
                          value={values.cost}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            select cost
                          </option>
                          <option value="Paid">Paid</option>
                          <option value="Free">Free</option>
                        </select>
                      </div>
                      {touched.cost && errors.cost && (
                        <p className="error p-1">{errors.cost}</p>
                      )}
                    </div>
                    <div className="w-100">
                      <div className="mb-3">
                        <label>Enter Title</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter Title"
                          name="title"
                          value={values.title}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      {touched.title && errors.title && (
                        <p className="error p-1">{errors.title}</p>
                      )}
                      <div className="mb-3">
                        <label>Enter Time</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter Time"
                          name="time"
                          value={values.time}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      {touched.time && errors.time && (
                        <p className="error p-1">{errors.time}</p>
                      )}
                      <div className="mb-3">
                        <label>Enter Author</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter Author"
                          name="author"
                          value={values.author}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      {touched.author && errors.author && (
                        <p className="error p-1">{errors.author}</p>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="mb-3">
                      <label>Enter Heading</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter Heading"
                        name="heading"
                        value={values.heading}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.heading && errors.heading && (
                      <p className="error p-1">{errors.heading}</p>
                    )}
                    <TextEditor
                      name="description"
                      stateKey="overViewDes"
                      value={values.description}
                      handleChange={(e) => {
                        handelChange(e);
                      }}
                      CustomStyle={"30vh"}
                    />
                    {touched.description && errors.description && (
                      <p className="error" style={{ marginTop: "1px" }}>
                        {errors.description}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="mb-3 w-100">
                      <label>Enter URL</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter URL"
                        name="url"
                        value={values.url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.url && errors.url && (
                      <p className="error p-1">{errors.url}</p>
                    )}

                    <div className="drag-input-field">
                      <label>Upload Image </label>
                      <input
                        type="file"
                        accept="image/*"
                        name="backgroundImg"
                        onBlur={handleBlur}
                        onChange={({ target }) => {
                          onChangeFile(target);
                        }}
                        ref={uploadImageRef}
                      />
                      {values?.backgroundImg === "" ? (
                        <label
                          className="m-0"
                          onClick={handleSelectUploadImage}
                        >
                          <span className="upload-text">
                            <i className="fa-solid fa-cloud-arrow-up" />
                            Upload
                          </span>
                        </label>
                      ) : (
                        <div className="position-relative ">
                          <img
                            src={uploadImg}
                            style={{
                              height: "200px",
                              width: "100%",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                            alt="..."
                          />
                          <button
                            className="removeimg"
                            type="button"
                            onClick={() => handelRemoveUploadImg()}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                    {touched.backgroundImg && errors.backgroundImg && (
                      <p className="error mt-1">{errors.backgroundImg}</p>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </CustomModal>
      );
    } else if (LibraryModal.type === "Edit") {
      return (
        <CustomModal
          open={LibraryModal.open}
          title={LibraryModal.title}
          actionTitle={LibraryModal.actionTitle}
          cancelTitle="Cancel"
          actionButtonClass="bg-danger"
          onConfirm={LibraryModal.onConfirm}
          isLoading={isLoadingAddLibrary}
          onClose={handleCloseModal}
          customClass="modal-xl"
        >
          <div>
            <form>
              <div className="upload_container">
                <div className="w-100 mt-2">
                  <div className="d-flex w-100 gap-4">
                    <div className="w-100">
                      <div className="mb-3">
                        <label for="cars">Select Skill </label>
                        <select
                          className="form-control"
                          style={{ padding: "12px" }}
                          name="skillId"
                          id="cars"
                          value={values?.skillId}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            select skill
                          </option>
                          {skillOption?.map((value) => (
                            <option key={value._id} value={value._id}>
                              {value?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {touched?.skillId && errors?.skillId && (
                        <p className="error p-1">{errors?.skillId}</p>
                      )}
                      <div className="mb-3">
                        <label for="cars">Select Type </label>
                        <select
                          className="form-control"
                          style={{ padding: "12px" }}
                          name="type"
                          id="cars"
                          value={values?.type}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            select type
                          </option>
                          {options?.map((value) => (
                            <option key={value.title} value={value.title}>
                              {value.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      {touched.type && errors.type && (
                        <p className="error p-1">{errors.type}</p>
                      )}
                      <div className="mb-3">
                        <label for="cars">Select cost </label>

                        <select
                          className="form-control"
                          style={{ padding: "12px" }}
                          name="cost"
                          id="cars"
                          value={values?.cost}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option value="" disabled>
                            select cost
                          </option>
                          <option value="Paid">Paid</option>
                          <option value="Free">Free</option>
                        </select>
                      </div>
                      {touched?.cost && errors?.cost && (
                        <p className="error p-1">{errors?.cost}</p>
                      )}
                    </div>
                    <div className="w-100">
                      <div className="mb-3">
                        <label>Enter Title</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter Title"
                          name="title"
                          value={values?.title}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      {touched?.title && errors?.title && (
                        <p className="error p-1">{errors?.title}</p>
                      )}
                      <div className="mb-3">
                        <label>Enter Time</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter Time"
                          name="time"
                          value={values?.time}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      {touched?.time && errors?.time && (
                        <p className="error p-1">{errors?.time}</p>
                      )}
                      <div className="mb-3">
                        <label>Enter Author</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter Author"
                          name="author"
                          value={values?.author}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      {touched?.author && errors?.author && (
                        <p className="error p-1">{errors?.author}</p>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="mb-3">
                      <label>Enter Heading</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter Heading"
                        name="heading"
                        value={values?.heading}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched?.heading && errors?.heading && (
                      <p className="error p-1">{errors?.heading}</p>
                    )}
                    <TextEditor
                      name="description"
                      stateKey="overViewDes"
                      value={values?.description}
                      handleChange={(e) => {
                        handelChange(e);
                      }}
                      CustomStyle={"30vh"}
                    />
                    {touched?.description && errors?.description && (
                      <p className="error" style={{ marginTop: "1px" }}>
                        {errors?.description}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="mb-3 w-100">
                      <label>Enter URL</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter URL"
                        name="url"
                        value={values?.url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched?.url && errors?.url && (
                      <p className="error p-1">{errors?.url}</p>
                    )}

                    <div className="drag-input-field">
                      <label>Upload Image </label>
                      <input
                        type="file"
                        accept="image/*"
                        name="backgroundImg"
                        onBlur={handleBlur}
                        onChange={({ target }) => {
                          onChangeFile(target);
                        }}
                        ref={uploadImageRef}
                      />
                      {values?.backgroundImg === "" ? (
                        <label
                          className="m-0"
                          onClick={handleSelectUploadImage}
                        >
                          <span className="upload-text">
                            <i className="fa-solid fa-cloud-arrow-up" />
                            Upload
                          </span>
                        </label>
                      ) : (
                        <div className="position-relative ">
                          <img
                            src={uploadImg}
                            style={{
                              height: "200px",
                              width: "100%",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                            alt="..."
                          />
                          <button
                            className="removeimg"
                            type="button"
                            onClick={() => handelRemoveUploadImg()}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                    {touched.backgroundImg && errors.backgroundImg && (
                      <p className="error mt-1">{errors.backgroundImg}</p>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </CustomModal>
      );
    } else return null;
  };

  const renderReviewModal = () => {
    return (
      resourceId && (
        <ReviewModal
          open={reviewModal}
          cancelTitle="Close"
          actionButtonClass="bg-danger"
          isLoading={false}
          onClose={() => {
            setReviewModal(false);
            setResourceId("");
          }}
          actionButtonNotRequire={true}
          resourceId={resourceId}
        />
      )
    );
  };

  useEffect(() => {
    if (LibraryModal.type === "Edit") {
      setValues({
        resource_id: LibraryModal?.data?._id,
        skillId: LibraryModal?.data?.skill_id,
        type: LibraryModal?.data?.resource_json?.title,
        author: LibraryModal?.data?.resource_json?.author,
        cost: LibraryModal?.data?.resource_json?.cost_type,
        description: LibraryModal?.data?.resource_json?.overViewDes,
        url: LibraryModal?.data?.resource_json?.learnMoreLink,
        time: LibraryModal?.data?.resource_json?.time,
        heading: LibraryModal?.data?.resource_json?.overViewTitle,
        backgroundImg: LibraryModal?.data?.resource_json?.image?.added_image,
        title: LibraryModal?.data?.resource_json?.description,
      });
      setUploadImg(LibraryModal?.data?.resource_json?.image?.added_image);
    }
  }, [LibraryModal?.data]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12 mt-4 px-1">
          <div className="card">
            <div className="card-header pb-0 px-3">
              <div className="position-relative my-3 d-flex justify-center">
                <h5 className="mb-0">Library Resources</h5>
                {false ? null : (
                  <button
                    title="Add"
                    type="button"
                    className="btn btn-green text-uppercase position-absolute top-0 end-0"
                    onClick={(e) => handleModalOpen(e)}
                  >
                    Add resource
                  </button>
                )}
              </div>
            </div>
            <div className="card-body pt-4 p-3">
              {isLoadingLibrary ? (
                <SkillListingSkeleton />
              ) : LibraryListData?.length === 0 ? (
                <NotFound content="Library List Not Found" />
              ) : (
                <ul className="list-group">
                  {LibraryListData?.map((item, index) => {
                    return (
                      <li
                        className="list-group-item border-0 p-4 mb-2 bg-gray-100 border-radius-lg"
                        key={index}
                      >
                        <div className="row justify-content-between gy-3">
                          <div className="card-flex d-flex col-sm-10">
                            <img
                              src={item?.resource_json?.image?.added_image}
                              alt="Image"
                              className="item-img rounded-2"
                            />
                            <div className="item-content d-flex flex-column">
                              <h6 className="mb-3 text-lg">
                                {item?.resource_json?.description}
                              </h6>
                              <div className="d-flex flex-column  gap-2">
                                {/* <div className="cost-status">
                                  <p className="m-0">
                                    {item?.resource_json?.title}
                                  </p>
                                </div>
                                

                                <div className="cost-status">
                                  <p className="m-0 ">
                                    {item?.resource_json?.cost_type}
                                  </p>
                                </div> */}
                                <span style={{ fontSize: "12px" }}>
                                  Select Type : {item?.resource_json?.title}
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  Skill : {item?.skill_name}
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  Author : {item?.resource_json?.author}
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    paddingRight: "12px",
                                  }}
                                >
                                  Time : {item?.resource_json?.time}
                                </span>
                              </div>

                              {/* <div className="d-flex gap-5 mt-4 ">
                                <span style={{ fontSize: "12px" }}>
                                  {item?.resource_json?.author}
                                </span>
                              </div> */}
                            </div>
                          </div>

                          <div className="col-sm-2 ms-sm-auto text-end">
                            <div className="d-flex flex-column gap-2 gap-sm-6 gap-md-6 ">
                              <div className="review_div">
                                <button
                                  className="review_btn py-1"
                                  onClick={() => handelReviewModal(item?._id)}
                                >
                                  Review
                                </button>
                              </div>

                              <div>
                                <a
                                  className="btn btn-link text-danger text-gradient px-3 mb-0"
                                  onClick={() =>
                                    handelDeleteSkillModal(item?._id)
                                  }
                                >
                                  <i className="far fa-trash-alt me-2" />
                                  Delete
                                </a>
                                <a
                                  title="Edit"
                                  className="btn btn-link text-dark px-3 mb-0"
                                  onClick={(e) => handleModalOpen(e, item)}
                                >
                                  <i
                                    className="fas fa-pencil-alt text-dark me-2"
                                    aria-hidden="true"
                                  />
                                  Edit
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      {returnModal()}
      {renderReviewModal()}
      <CustomModal
        customClass="sm-modal"
        open={deleteLibraryModal}
        title="Library Delete"
        actionTitle="Delete"
        cancelTitle="Cancel"
        actionButtonClass="bg-danger"
        onConfirm={onDeleteConfirm}
        isLoading={isLoadingLibraryDelete}
        onClose={() => setDeleteLibraryModal(false)}
      >
        <div>
          <span>Are you sure you want to delete Library Resource?</span>
        </div>
      </CustomModal>
    </div>
  );
};

export default LibraryListScreen;
