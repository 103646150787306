/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import { ExerciseTemplateArray } from "../../utils/seeds/templateArray";
import CustomModal from "../../components/customModal/customModal";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import useGet from "../../hooks/useGet";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import userGlobalConfig from "../../utils/constants/globalConfig";
import useDelete from "../../hooks/useDelete";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../routes/routePath";
import usePost from "../../hooks/usePost";
import usePut from "../../hooks/usePut";
import DetailSkeleton from "../../utils/skeleton/detailsSkeleton";
import ImagesUrl from "../../utils/constants/images";
import {
  addExerciseInitialValues,
  addExerciseValidationSchema,
} from "../../utils/validations/exercises";
import TemplateExerciseIndex from "../templatesExercise";
import { numberOnly } from "../../utils/helpers";

const ExerciseDetail = () => {
  const [templateActive, setTemplateActive] = useState(1);
  const [editExerciseModal, setEditExerciseModal] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);
  const [uploadImg, setUploadImg] = useState(null);
  const [deleteExerciseModal, setDeleteExerciseModal] = useState(false);
  const [deleteStepId, setDeleteStepId] = useState(null);
  const uploadImageRef = useRef(null);
  const nav = useNavigate();
  const exerciseId = JSON.parse(
    localStorage.getItem(userGlobalConfig?.EXERCISE_ID)
  );
  const {
    data: exerciseDetailData,
    isLoading: isLoadingExerciseDetail,
    isFetching: isFetchingExerciseDetail,
    refetch: refetchExerciseDetail,
  } = useGet(ENDPOINTS.VIEW_EXERCISE, QUERY_KEYS.VIEW_EXERCISE_QUERY_KEY, {
    exercise_id: exerciseId,
  });

  const {
    mutateAsync: editExerciseMutateAsync,
    isLoading: isLoadingEditExercise,
  } = usePut(ENDPOINTS.EDIT_EXERCISE, QUERY_KEYS.EDIT_EXERCISES_QUERY_KEY);
  const {
    isLoading: isLoadingExerciseDelete,
    mutateAsync: exerciseDeleteMutateAsync,
  } = useDelete(
    ENDPOINTS.DELETE_EXERCISE,
    QUERY_KEYS.DELETE_EXERCISES_QUERY_KEY
  );
  const {
    isLoading: isLoadingExerciseStatus,
    mutateAsync: exerciseStatusMutateAsync,
  } = usePost(ENDPOINTS.EXERCISE_STATUS, QUERY_KEYS.EXERCISE_STATUS_QUERY_KEY);

  const exerciseData = exerciseDetailData?.data;

  const handelActiveTemplate = (index) => {
    setTemplateActive(index);
    window.scrollTo(0, 700);
  };
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: addExerciseInitialValues,
    validationSchema: addExerciseValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("exercise_id", exerciseId);
      formData.append("title", values?.exerciseTitle);
      formData.append("points", values?.exercisePoints);
      formData.append("extimated_time", values?.exerciseTime);
      formData.append("image", values?.exerciseImage);
      try {
        const response = await editExerciseMutateAsync(formData);
        if (response.statusCode === 200) {
          toast.success(response?.message);
          setEditExerciseModal(false);
          resetForm();
          refetchExerciseDetail();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });
  const onChangeFile = (target) => {
    const selectedFile = target.files[0];
    setUploadImg(URL.createObjectURL(selectedFile));
    setFieldValue("exerciseImage", selectedFile);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };
  const handelRemoveUploadImg = () => {
    setUploadImg(null);
    setFieldValue("exerciseImage", "");
  };
  const handelDeleteStepModal = (id) => {
    setDeleteStepId(id);
    setDeleteExerciseModal(true);
  };
  const onDeleteConfirm = async () => {
    try {
      const response = await exerciseDeleteMutateAsync({
        exercise_id: exerciseId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteExerciseModal(false);
        nav(RoutePath.SKILL_DETAIL.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handleStatusChange = async (item) => {
    try {
      let statusCode = item.status === 1 ? "2" : "1";
      const response = await exerciseStatusMutateAsync({
        exercise_id: exerciseId,
        status: statusCode,
      });
      if (response.status === true) {
        toast.success(response?.message);
        refetchExerciseDetail();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    setValues({
      exerciseTitle: exerciseData?.title,
      exerciseTime: exerciseData?.extimated_time,
      exerciseImage: exerciseData?.image,
      exercisePoints: exerciseData?.points,
    });
  }, [exerciseData, editExerciseModal]);

  useEffect(() => {
    if (exerciseData?.type) {
      setTemplateActive(exerciseData?.type ?? 1);
      setEditTemplate(true);
    } else {
      setEditTemplate(true);
      setTemplateActive(1);
    }
  }, [exerciseData]);

  const handleEditForm = (status) => {
    setEditTemplate(status ?? !editTemplate);
  };

  return (
    <div>
      <div className="row px-3">
        {isLoadingExerciseDetail ? (
          <DetailSkeleton />
        ) : (
          <div className="card position-relative">
            <img
              className="back_btn"
              src={ImagesUrl.back_btn}
              alt=".."
              onClick={() =>
                nav(RoutePath.SKILL_DETAIL.path, {
                  state: { headerName: RoutePath.SKILL_DETAIL.title },
                })
              }
            />
            <div className="card-body">
              <div className="row gy-3">
                <div className="col-12">
                  <div className="d-flex justify-content-end align-items-center gap-1">
                    <i
                      className="fas fa-pencil-alt text-dark px-1 cursor-pointer"
                      onClick={() => setEditExerciseModal(true)}
                    ></i>
                    <i
                      className="far fa-trash-alt  text-danger px-1 cursor-pointer "
                      onClick={() => handelDeleteStepModal(exerciseData?._id)}
                    ></i>
                    <span className="toggle-box px-1">
                      <input
                        onClick={() => handleStatusChange(exerciseData)}
                        type="checkbox"
                        checked={exerciseData?.status === 1 ? true : false}
                        id={1}
                        disabled={isLoadingExerciseStatus}
                      />
                      <label className="m-0" htmlFor={1} />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-sm-4 col-lg-3">
                      <div className="full-image-blk">
                        <img src={exerciseData?.image} alt="Image" />
                      </div>
                    </div>
                    <div className="col-sm-8 col-lg-9">
                      <div className="content-blk">
                        <h1 className="h2">{exerciseData?.title}</h1>
                        <span>
                          Time Estimate : {exerciseData?.extimated_time} min
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {editTemplate ? (
        <div className="card-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="flex-start-btn mb-4">
                <h2 className="h5">Select Template</h2>
              </div>
            </div>
          </div>
          <div className="row row-cols-2 row-cols-sm-2 row-cols-lg-3 row-cols-xl-3 gy-4">
            {ExerciseTemplateArray?.map((item, index) => {
              return (
                <div
                  className="col cursor-pointer"
                  key={index}
                  onClick={() => handelActiveTemplate(item?.id)}
                >
                  <div
                    className={
                      templateActive == item?.id ? "card active" : "card"
                    }
                  >
                    <div className="card-header  text-center">
                      <div className="icon icon-shape icon-sm bg-gradient-primary shadow text-center border-radius-sm">
                        {item.icon}
                      </div>
                    </div>
                    <div className="card-body p-2 m-0 text-center">
                      <h6 className="text-center ">{item.name}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      <TemplateExerciseIndex
        index={templateActive}
        data={exerciseData}
        exerciseId={exerciseId}
        handleEditForm={handleEditForm}
        editTemplate={editTemplate}
        dataLoading={isLoadingExerciseDetail}
        refetchStepDetail={refetchExerciseDetail}
      />

      <CustomModal
        open={editExerciseModal}
        title={"Edit Exercise"}
        actionTitle={"Save"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={handleSubmit}
        isLoading={isLoadingEditExercise}
        onClose={() => {
          setEditExerciseModal(false);
          resetForm();
        }}
      >
        <div>
          <form>
            <div className="mb-3">
              <label>Title</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Enter Journey Name"
                name="exerciseTitle"
                value={values.exerciseTitle}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {touched.exerciseTitle && errors.exerciseTitle && (
              <p className="error">{errors.exerciseTitle}</p>
            )}
            <div className="mb-3">
              <label>Time Estimate</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Enter Estimate Time"
                name="exerciseTime"
                value={values.exerciseTime}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {touched.exerciseTime && errors.exerciseTime && (
              <p className="error">{errors.exerciseTime}</p>
            )}
            <div className="mb-3">
              <label>Points</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Enter Exercise Points "
                name="exercisePoints"
                value={values.exercisePoints}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={numberOnly}
              />
            </div>
            {touched.exercisePoints && errors.exercisePoints && (
              <p className="error">{errors.exercisePoints}</p>
            )}
            <div className="drag-input-field mt-3">
              <label>Upload Image </label>
              <input
                type="file"
                accept="image/*"
                name="stepImage"
                onBlur={handleBlur}
                onChange={({ target }) => {
                  onChangeFile(target);
                }}
                ref={uploadImageRef}
              />
              {values?.exerciseImage?.length === 0 ? (
                <label className="m-0" onClick={handleSelectUploadImage}>
                  <span className="upload-text">
                    <i className="fa-solid fa-cloud-arrow-up" />
                    Upload image
                  </span>
                </label>
              ) : (
                <div className="position-relative border-1 border border-gray rounded-2 d-flex justify-content-center p-2">
                  <img
                    src={uploadImg === null ? values.exerciseImage : uploadImg}
                    style={{
                      height: "200px",
                      width: "200px",

                      objectFit: "contain",
                      borderRadius: "5px",
                      border: "solid 1px #8080808a",
                    }}
                    alt="..."
                  />
                  <button
                    className="removeimg"
                    type="button"
                    onClick={() => handelRemoveUploadImg()}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
            {touched.exerciseImage && errors.exerciseImage && (
              <p className="error" style={{ marginTop: "4px" }}>
                {errors.exerciseImage}
              </p>
            )}
          </form>
        </div>
      </CustomModal>
      <CustomModal
        customClass="sm-modal"
        open={deleteExerciseModal}
        title={"Exercise Delete"}
        actionTitle={"Delete"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={onDeleteConfirm}
        isLoading={isLoadingExerciseDelete}
        onClose={() => setDeleteExerciseModal(false)}
      >
        <div>
          <span>Are you sure you want to delete Exercise?</span>
        </div>
      </CustomModal>
    </div>
  );
};

export default ExerciseDetail;
