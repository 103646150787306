/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import usePost from "../../hooks/usePost";
import useGet from "../../hooks/useGet";
import { useFormik } from "formik";

import { toast } from "react-toastify";
import CustomModal from "../../components/customModal/customModal";
import SkillListingSkeleton from "../../utils/skeleton/skillListingSkeleton";
import NotFound from "../../components/notFound";
import useDelete from "../../hooks/useDelete";
import {
  sendNotificationInitialValues,
  sendNotificationValidationSchema,
} from "../../utils/validations/sendNotification";

const Notification = () => {
  const [deleteSkillModal, setDeleteSkillModal] = useState(false);
  const [deleteAboutUsId, setDeleteAboutUsId] = useState(null);

  const [notificationModal, setNotificationModal] = useState(false);

  const {
    data: aboutUsListData,
    isLoading: isLoadingAboutUs,
    refetch: refetchAboutUsList,
  } = useGet(ENDPOINTS.ABOUT_US, QUERY_KEYS.ABOUT_US_QUERY_KEY);

  const aboutUsData = aboutUsListData?.data?.our_missions;

  const {
    isLoading: isLoadingNotificationStatus,
    mutateAsync: notificationStatusMutateAsync,
  } = usePost(
    ENDPOINTS.SEND_NOTIFICATION,
    QUERY_KEYS.SEND_NOTIFICATION_QUERY_KEY
  );
  const {
    isLoading: isLoadingAboutUsDelete,
    mutateAsync: aboutUsDeleteMutateAsync,
  } = useDelete(
    ENDPOINTS.DELETE_ABOUT_US,
    QUERY_KEYS.DELETE_ABOUT_US_QUERY_KEY
  );

  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    values,
  } = useFormik({
    initialValues: sendNotificationInitialValues,
    validationSchema: sendNotificationValidationSchema,
    onSubmit: async (value) => {
      try {
        const response = await notificationStatusMutateAsync({
          title: value.title,
          message: value.message,
        });
        if (response.status === true) {
          setNotificationModal(false);
          toast.success(response?.message);
          resetForm();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const handelDeleteSkillModal = (id) => {
    setDeleteAboutUsId(id);
    setDeleteSkillModal(true);
  };
  const onDeleteConfirm = async () => {
    try {
      const response = await aboutUsDeleteMutateAsync({
        _id: deleteAboutUsId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteSkillModal(false);
        refetchAboutUsList();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleModalOpen = () => {
    setNotificationModal(true);
  };

  const returnModal = () => {
    return (
      <CustomModal
        customClass="md-modal"
        open={notificationModal}
        title="Send Notification"
        actionTitle="Send"
        cancelTitle="Cancel"
        actionButtonClass="bg-danger"
        onConfirm={handleSubmit}
        isLoading={isLoadingNotificationStatus}
        onClose={() => {
          handleCloseModal(false);
          resetForm();
        }}
      >
        <div>
          <div class="mb-3 mt-2">
            <div className="form-group">
              <label
                htmlFor="example-text-input"
                className="form-control-label"
              >
                Title
              </label>
              <input
                className="form-control "
                type="text"
                placeholder="title"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.title && touched.title ? (
              <p className="error">{errors.title}</p>
            ) : null}
            <label> Message </label>
            <textarea
              class="form-control form-control-lg"
              placeholder="Wright here ..."
              rows="7"
              cols="50"
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
            {errors.message && touched.message ? (
              <p className="error mt-2"> {errors.message}</p>
            ) : null}
          </div>
        </div>
      </CustomModal>
    );
  };

  const handleCloseModal = () => {
    setNotificationModal(false);
    resetForm();
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-12 mt-4 px-1">
            <div className="card">
              <div className="card-header pb-0 px-3">
                <div className="position-relative my-3 d-flex justify-center">
                  <h5 className="mb-0">Notification List</h5>
                  {isLoadingAboutUs ? null : (
                    <button
                      title="Add"
                      type="button"
                      className="btn btn-green text-uppercase position-absolute top-0 end-0 "
                      onClick={(e) => handleModalOpen(e)}
                    >
                      Send Notification
                    </button>
                  )}
                </div>
              </div>
              <div className="card-body pt-4 p-3">
                {isLoadingAboutUs ? (
                  <SkillListingSkeleton />
                ) : (
                  <ul className="list-group">
                    {!null ? (
                      <NotFound content="Notification List Not Found" />
                    ) : (
                      aboutUsData?.map((item, index) => {
                        return (
                          <li
                            className="list-group-item border-0 p-4 mb-2 bg-gray-100 border-radius-lg"
                            key={index}
                          >
                            <div className="row justify-content-between gy-3">
                              <div className="card-flex d-flex cursor-pointer col-sm-10">
                                <img
                                  src={item.image}
                                  alt="Image"
                                  className="item-img rounded-2"
                                />

                                <div className="item-content d-flex flex-column ">
                                  <h6 className="mb-3 text-lg">{item.title}</h6>
                                  <span className="mb-2 text-md">
                                    <span className="text-dark font-weight-normal">
                                      {item.description}
                                    </span>
                                  </span>
                                </div>
                              </div>

                              <div className="col-sm-2 align-self-end ms-sm-auto text-end">
                                <a
                                  className="btn btn-link text-danger text-gradient px-3 mb-0"
                                  onClick={() =>
                                    handelDeleteSkillModal(item?._id)
                                  }
                                >
                                  <i className="far fa-trash-alt me-2" />
                                  Delete
                                </a>
                                <a
                                  title="Edit"
                                  className="btn btn-link text-dark px-3 mb-0"
                                  onClick={(e) => handleModalOpen(e, item)}
                                >
                                  <i
                                    className="fas fa-pencil-alt text-dark me-2"
                                    aria-hidden="true"
                                  />
                                  Edit
                                </a>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {returnModal()}
      <CustomModal
        customClass="sm-modal"
        open={deleteSkillModal}
        title={"About Us Delete"}
        actionTitle={"Delete"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={onDeleteConfirm}
        isLoading={isLoadingAboutUsDelete}
        onClose={() => setDeleteSkillModal(false)}
      >
        <div>
          <span>Are you sure you want to delete About Us?</span>
        </div>
      </CustomModal>
    </div>
  );
};

export default Notification;
