/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import ImagesUrl from "../../utils/constants/images";
import TempLayout from "../../components/UI/tempLayout";
import ScreenLayout from "../../components/UI/screenLayout";
import JournalComponent from "../../components/UI/journalComponent";
import usePost from "../../hooks/usePost";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import { toast } from "react-toastify";
import { uniqueIdGenerator } from "../../utils/helpers";
import { RoutePath } from "../../routes/routePath";
import { useNavigate } from "react-router-dom";

const Journal = ({ props }) => {
  const {
    data,
    templateId,
    handleEditForm,
    editTemplate,
    refetchStepDetail,
    dataLoading,
    stepId,
  } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInput = useRef(null);
  const [form1, setForm1] = useState({
    formId: uniqueIdGenerator(),
    formType: "simple continue",
    description: "It’s time to put your knowledge to the test!",
    buttonText: "Tap To Continue",
  });
  const [journalSubForm, setJournalSubForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "Journal",
    title: "Select an option to use as a prompt for your self-reflection.",
    buttonText: "Begin reflection",
  });
  const [journalData, setJournalData] = useState([
    {
      id: uniqueIdGenerator(),
      title: "Reflection #1",
      description:
        "What would you say to yourself if you were your own best friend who is struggling with low self-confidence?",
    },
  ]);
  const [form3, setForm3] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",

    title: "Take a moment to let that reflection sink in.",

    buttonText: "Continue",
  });
  const [finalForm, setFinalForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "final",
    image: {
      form_unique_id: "",
      added_image: "",
    },
    title: "Congrats!",
    description: "You completed a Journal entry. Excellent work! ",
    buttonText: "Continue",
  });
  const nav = useNavigate();
  const { mutateAsync: addImagesMutateAsync, isLoading: isLoadingAddImages } =
    usePost(ENDPOINTS.TEMPLATE_IMAGES, QUERY_KEYS.TEMPLATE_IMAGES_QUERY_KEY);
  const {
    mutateAsync: addTemplateMutateAsync,
    isLoading: isLoadingAddTemplate,
  } = usePost(
    ENDPOINTS.TEMPLATE_DATA_ADD,
    QUERY_KEYS.TEMPLATE_DATA_ADD_QUERY_KEY
  );
  const uploadFormJson = async (formData) => {
    const params = {
      step_id: stepId,
      sub_step_id: data?._id,
      template_id: templateId,
      template_json: formData,
    };

    try {
      const response = await addTemplateMutateAsync(params);

      if (response.statusCode === 200) {
        refetchStepDetail();
        toast.success(response?.message);
        handleEditForm();
        nav(RoutePath.SKILL_DETAIL.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    }
    //add upload form api
  };

  const handelApply = async (imageResponse) => {
    const formData = [
      {
        name: data?.name ?? "Journal",
        form_json: [
          form1,
          { ...journalSubForm, resources: journalData },
          form3,
          {
            ...finalForm,
            image: imageResponse?.data ?? finalForm.image,
          },
        ],
      },
    ];

    if (imageResponse !== null) {
      setFinalForm({
        ...finalForm,
        image: imageResponse?.data,
      });
    }
    uploadFormJson(formData);
  };
  const addNewJournal = () => {
    const length = journalData.length + 1;
    setJournalData([
      ...journalData,
      {
        id: uniqueIdGenerator(),
        title: `Reflection #${length}`,
        description:
          "What would you say to yourself if you were your own best friend who is struggling with low self-confidence?",
      },
    ]);
  };
  const handleJournalText = (event, index, key) => {
    setJournalData((prevResource) => {
      prevResource[index][key] = event.target.innerText;
      return [...prevResource];
    });
  };
  const handleFileInput = (target) => {
    setSelectedFile(target.files[0]);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };
  const removeJournal = (i) => {
    const newResource = [...journalData];
    newResource.splice(i, 1);
    setJournalData(newResource);
  };
  const handleUpload = async (callback) => {
    if (selectedFile == null) {
      callback(null);
    } else {
      const imageId = finalForm?.image?.form_unique_id ?? "";
      const formData = new FormData();
      formData.append("step_id", stepId);
      formData.append("sub_step_id", data?._id);
      formData.append("template_id", templateId);
      formData.append("form_unique_id", imageId);
      formData.append("image", selectedFile);
      try {
        const response = await addImagesMutateAsync(formData);

        if (response.statusCode === 200) {
          callback(response);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };
  useEffect(() => {
    if (data?.template_id == 7) {
      const forms = data?.form_json;
      if (forms && forms?.length !== 0) {
        setForm1(forms[0]?.form_json[0]);
        setJournalSubForm(forms[0]?.form_json[1]);
        setJournalData(forms[0]?.form_json[1]?.resources);
        setForm3(forms[0]?.form_json[2]);
        setFinalForm(forms[0]?.form_json[3]);
      } else {
        handleEditForm(true);
      }
    }
  }, [, refetchStepDetail]);
  return (
    <TempLayout
      handleEditForm={handleEditForm}
      editTemplate={editTemplate}
      handleApply={() => handleUpload(handelApply)}
      loading={isLoadingAddTemplate || isLoadingAddImages}
      dataLoading={dataLoading}
    >
      <section className="screen-section">
        <div className="container-fluid gx-0">
          <div className="row gap-3">
            <div className="col-12">
              <div className="screen-wrapper">
                <ScreenLayout name={data.name}>
                  <div className="screen-body">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm1({
                                ...form1,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form1.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm1({
                            ...form1,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form1.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data.name}>
                  <div
                    className="checkbox-wrapper"
                    style={{ maxHeight: "45px", marginTop: "0px" }}
                  >
                    <div className="checkbox-list">
                      <div className="sm-content">
                        <p
                          contentEditable={editTemplate}
                          onBlur={(e) => {
                            setJournalSubForm({
                              ...journalSubForm,
                              title: e.target.innerText,
                            });
                          }}
                        >
                          {journalSubForm?.title}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="screen-body">
                    <div className="add-item-blk">
                      <button
                        className="add-item-btn"
                        onClick={editTemplate ? addNewJournal : null}
                      >
                        Add More
                      </button>
                    </div>
                    <div class="reflection-wrapper">
                      {journalData?.map((item, index, array) => {
                        return (
                          <JournalComponent
                            index={index}
                            handleJournalText={handleJournalText}
                            item={item}
                            editTemplate={editTemplate}
                            removeJournal={removeJournal}
                            array={array}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setJournalSubForm({
                            ...journalSubForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {journalSubForm?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>

                <ScreenLayout name={data?.name} notHeaderRequire="true">
                  <div className="screen-body px-35 item-center">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content d-flex justify-content-center w-100 ">
                          <p
                            className="ls-font ft-22 lht-32 pb-2 "
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm3({
                                ...form3,
                                title: e.target.innerText,
                              });
                            }}
                          >
                            {form3?.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm3({
                            ...form3,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form3?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data?.name} notHeaderRequire="true">
                  <div className="screen-body px-35 item-center">
                    <div className="upload-img-blk">
                      <img
                        className="preview-img"
                        src={
                          selectedFile === null
                            ? finalForm?.image?.added_image === ""
                              ? ImagesUrl.shape_image
                              : `https://leadherself-api.csdevhub.com/src/public/form_images/${finalForm?.image?.added_image}`
                            : URL.createObjectURL(selectedFile)
                        }
                        alt="Image"
                      />
                      <div className="upload-img-file">
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          ref={fileInput}
                          onChange={({ target }) => handleFileInput(target)}
                        />
                        <label
                          htmlFor="ImageUpload"
                          onClick={
                            editTemplate ? handleSelectUploadImage : null
                          }
                        >
                          <i className="fa-solid fa-image" />
                        </label>
                      </div>
                    </div>
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "300px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content d-flex justify-content-center w-100 ">
                          <p
                            className="ls-font ft-22 lht-32 pb-2 "
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                title: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm?.title}
                          </p>
                        </div>
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setFinalForm({
                            ...finalForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {finalForm?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TempLayout>
  );
};

export default Journal;
