/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";

import CustomModal from "../customModal/customModal";
import { AuthContext } from "../../contexts/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SidebarArray } from "../../utils/seeds/sidebarArray";
import { useFormik } from "formik";
import {
  UpdatePasswordSchema,
  initialValuesUpdatePassword,
} from "../../utils/validations/changePassword";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import usePut from "../../hooks/usePut";
import { RoutePath } from "../../routes/routePath";
import userGlobalConfig from "../../utils/constants/globalConfig";

const GenericPage = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [sideBarHide, setSideBarHide] = useState(true);
  const [changePassword, setChangePassword] = useState(false);
  const [passwordHide, setPasswordHide] = useState({
    oldPassword: true,
    newPassword: true,
    confirmPassword: true,
  });
  const [notificationHide, setNotificationHide] = useState(false);
  const { logout } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const headerName = useLocation();
  const nav = useNavigate();
  const {
    mutateAsync: adminChangePasswordMutateAsync,
    isLoading: isLoadingAdminChangePassword,
  } = usePut(ENDPOINTS.CHANGE_PASSWORD, QUERY_KEYS.CHANGE_PASSWORD_QUERY_KEY);
  const handleNavigation = (item) => {
    nav(item.path, { state: { headerName: item.name } });
    setSideBarHide(true);
  };
  const handlePassword = (type) => {
    if (type === "old") {
      setPasswordHide({
        ...passwordHide,
        oldPassword: !passwordHide.oldPassword,
      });
    } else if (type === "new") {
      setPasswordHide({
        ...passwordHide,
        newPassword: !passwordHide.newPassword,
      });
    } else if (type === "confirm") {
      setPasswordHide({
        ...passwordHide,
        confirmPassword: !passwordHide.confirmPassword,
      });
    } else {
      return null;
    }
  };
  const handelLogout = () => {
    logout();
    toast.success("log out successfully");
  };
  const handelSidebar = () => {
    setSideBarHide(false);
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValuesUpdatePassword,
    validationSchema: UpdatePasswordSchema,
    onSubmit: async (values) => {
      const data = {
        old_password: values.oldPassword,
        new_password: values.newPassword,
      };
      try {
        const response = await adminChangePasswordMutateAsync(data);
        if (response.success === true) {
          toast.success(response.message);
          setChangePassword(false);
          resetForm();
          logout();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error("Invalid email or password !");
      }
    },
  });

  const returnSideBarList = () => {
    return SidebarArray()?.map((item, index) => {
      if (
        localStorage.getItem(userGlobalConfig.ROLE) !== "1" &&
        item.name === RoutePath.ADMIN_MANAGEMENT.title
      ) {
        return null;
      } else {
        return (
          <li className="nav-item" key={index}>
            <a
              className={activeTab === index ? "nav-link active" : "nav-link "}
              onClick={() => handleNavigation(item)}
            >
              <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                {item.icon}
              </div>
              <span className="nav-link-text ms-1">{item.name}</span>
            </a>
          </li>
        );
      }
    });
  };
  useEffect(() => {
    let currentPath = `/${window.location.hash.split("/")[1]}`;
    if (
      currentPath === RoutePath.USER_MANAGEMENT.path ||
      currentPath === RoutePath.USER_DETAIL.path ||
      currentPath === RoutePath.EDIT_USER_DETAIL.path
    ) {
      setActiveTab(0);
    } else if (
      currentPath === RoutePath.JOURNEY_MANAGEMENT.path ||
      currentPath === RoutePath.JOURNEY_DETAIL.path ||
      currentPath === RoutePath.SKILL_DETAIL.path ||
      currentPath === RoutePath.STEPS_DETAIL.path
    ) {
      setActiveTab(1);
    } else if (currentPath === RoutePath.CONTENT_MANAGEMENT.path) {
      setActiveTab(2);
    } else if (currentPath === RoutePath.LEADERBOARD_MANAGEMENT.path) {
      setActiveTab(3);
    } else if (currentPath === RoutePath.NOTIFICATION_MANAGEMENT.path) {
      setActiveTab(4);
    } else if (currentPath === RoutePath.LIBRARY_MANAGEMENT.path) {
      setActiveTab(5);
    } else if (currentPath === RoutePath.ADMIN_MANAGEMENT.path) {
      setActiveTab(6);
    }
  }, [`/${window.location.hash.split("/")[1]}`]);
  return (
    <div
      className={
        sideBarHide
          ? "g-sidenav-show bg-gray-100"
          : "g-sidenav-show bg-gray-100 g-sidenav-pinned"
      }
    >
      <div className="h-100 bg-primary position-absolute w-100" />
      <aside className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 bg-white sidebarbtn">
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0  d-xl-none"
            onClick={() => setSideBarHide(true)}
          />
          <a className="navbar-brand m-0">
            <img
              src="../assets/logos/main-logo.svg"
              className="navbar-brand-img h-100"
              alt="main_logo"
            />
            <span className="ms-1 font-weight-bold">LeadHerself</span>
          </a>
        </div>
        <hr className="horizontal dark mt-0" />
        <div className="d-grid gap-7">
          <div className="collapse navbar-collapse  w-auto ">
            <ul className="navbar-nav">{returnSideBarList()}</ul>
          </div>
          <div className="sidenav-footer mx-4   d-flex flex-column  justify-content-end ">
            <a className="nav-link" onClick={() => setChangePassword(true)}>
              <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="none"
              >
                <path
                  fill="#606a84"
                  d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
                />
              </svg>
              <span className="nav-link-text m-1">Update Password</span>
            </a>

            <a className=" nav-link" onClick={() => setOpen(true)}>
              <i class="fa-solid fa-arrow-right-from-bracket"></i>
              <span className="nav-link-text m-1">Log out</span>
            </a>
          </div>
        </div>
      </aside>
      <main className="main-content position-relative border-radius-lg ">
        <nav
          className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl "
          id="navbarBlur"
          data-scroll="false"
        >
          <div className="container-fluid py-1 px-3">
            <nav aria-label="breadcrumb">
              {/* <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm ">
                  <Link to="" className="opacity-5 ">
                    {headerName?.state?.headerName}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item text-sm  active"
                  aria-current="page"
                >
                  {headerName?.state?.headerName}
                </li>
              </ol> */}
              {/* <button onClick={handleBack}>back</button> */}

              <h4 className="font-weight-bolder mb-0 text-capitalize">
                {headerName?.state?.headerName}
              </h4>
            </nav>
            <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4">
              <div className="ms-md-auto pe-md-3 d-flex align-items-center"></div>
              <ul className="navbar-nav justify-content-end">
                <li className="nav-item d-flex align-items-center cursor-pointer">
                  <a className="nav-link  font-weight-bold ">
                    <i className="fa fa-user" />
                  </a>
                </li>
                <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                  <a className="nav-link p-0" onClick={handelSidebar}>
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line " />
                      <i className="sidenav-toggler-line " />
                      <i className="sidenav-toggler-line " />
                    </div>
                  </a>
                </li>

                {/* <li className="nav-item dropdown pe-2 d-flex align-items-center">
                  <a
                    className={
                      notificationHide ? "nav-link p-0" : "nav-link p-0 show"
                    }
                    onClick={() => setNotificationHide(!notificationHide)}
                  >
                    <i className="fa fa-bell cursor-pointer" />
                    <span class="notification">5</span>
                  </a>
                  <ul
                    className={
                      notificationHide
                        ? "dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4 show"
                        : "dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4 d-none "
                    }
                  >
                    {[1, 2, 3, 4, 5].map((item, index) => {
                      return (
                        <li className="mb-2" key={index}>
                          <a className="dropdown-item border-radius-md ">
                            <div className="d-flex py-1">
                              <div className="my-auto">
                                <img
                                  src="../assets/logos/main-logo.svg"
                                  className="avatar avatar-sm  me-3 "
                                />
                              </div>
                              <div className="d-flex flex-column justify-content-center">
                                <h6 className="text-sm font-weight-normal mb-1">
                                  <span className="font-weight-bold">
                                    New message
                                  </span>
                                  from Laur
                                </h6>
                                <p className="text-xs text-secondary mb-0">
                                  <i className="fa fa-clock me-1" />
                                  13 minutes ago
                                </p>
                              </div>
                            </div>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>

        <div className="container-fluid py-4"> {children}</div>
      </main>
      <CustomModal
        customClass="sm-modal"
        open={open}
        title={"Logout"}
        actionTitle={"Yes"}
        cancelTitle={"No"}
        actionButtonClass={"bg-danger"}
        onConfirm={handelLogout}
        onClose={() => setOpen(false)}
      >
        <div>
          <span> Are you sure you want to log out ?</span>
        </div>
      </CustomModal>
      <CustomModal
        customClass="xl-modal"
        open={changePassword}
        title={"Update Password"}
        actionTitle={"Confirm "}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={handleSubmit}
        isLoading={isLoadingAdminChangePassword}
        onClose={() => {
          setChangePassword(false);
          resetForm();
        }}
      >
        <div className="card-body">
          <form>
            <div className="mb-3 position-relative">
              <label>Old Password</label>
              <input
                type={passwordHide?.oldPassword ? "password" : "text"}
                className="form-control form-control-lg  "
                placeholder="Enter old password"
                name="oldPassword"
                value={values.oldPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                className="position-absolute top-50  end-3 "
                onClick={() => handlePassword("old")}
              >
                {passwordHide?.oldPassword ? (
                  <img
                    src="../assets/logos/visibility_off.svg"
                    alt="..."
                    width="17px"
                  />
                ) : (
                  <img
                    src="../assets/logos/visibility_on.svg"
                    alt="..."
                    width="17px"
                  />
                )}
              </div>
            </div>{" "}
            {touched.oldPassword && errors.oldPassword && (
              <p className="error">{errors.oldPassword}</p>
            )}
            <div className="mb-3 position-relative">
              <label>New Password</label>
              <input
                type={passwordHide.newPassword ? "password" : "text"}
                className="form-control form-control-lg  "
                placeholder=" Enter new password"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                className="position-absolute top-50  end-3 "
                onClick={() => handlePassword("new")}
              >
                {passwordHide.newPassword ? (
                  <img
                    src="../assets/logos/visibility_off.svg"
                    alt="..."
                    width="17px"
                  />
                ) : (
                  <img
                    src="../assets/logos/visibility_on.svg"
                    alt="..."
                    width="17px"
                  />
                )}
              </div>
            </div>
            {touched.newPassword && errors.newPassword && (
              <p className="error">{errors.newPassword}</p>
            )}
            <div className="mb-3 position-relative">
              <label>Confirm Password</label>
              <input
                type={passwordHide.confirmPassword ? "password" : "text"}
                className="form-control form-control-lg  "
                placeholder="Enter confirm  password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                className="position-absolute top-50  end-3 "
                onClick={() => handlePassword("confirm")}
              >
                {passwordHide.confirmPassword ? (
                  <img
                    src="../assets/logos/visibility_off.svg"
                    alt="..."
                    width="17px"
                  />
                ) : (
                  <img
                    src="../assets/logos/visibility_on.svg"
                    alt="..."
                    width="17px"
                  />
                )}
              </div>
            </div>{" "}
            {touched.confirmPassword && errors.confirmPassword && (
              <p className="error">{errors.confirmPassword}</p>
            )}
          </form>
        </div>
      </CustomModal>
    </div>
  );
};

export default GenericPage;
